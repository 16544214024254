import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import suite301 from '../../../images/suite-301-2.jpg';
import arrowIcon from '../../../images/arrow-icon.svg';
import Loader from '../../../components/Loader';

import '../../../styles/suites.scss';

const Suites = ({location}) => {

    const [ isLoading, setLoading ] = useState(true);

    useEffect(() => {
		if (document.getElementById('model-suite'))
			document.getElementById('model-suite').addEventListener('load', () => {
				setLoading(false)
			})
    }, []);

    return (
        <Layout currentPath={location}>
			<SEO title="Ínsula Living | Suites" />
            <div className="suites">
				<Link to="/proyectos/insula-living/tipos" className="back">
					&larr;Volver
				</Link>
				<div className="suites__title">
					<div className="suites__texture" />
					<h1>SUITES</h1>
                    <div className="suites__title__vendido-container">
                        <p>VENDIDOS</p>
                    </div>
				</div>
				<div className="suites__info-container">
                    <div className="caracteristicas">
                        <div className="caracteristicas__leftbar" />
                        <div className="caracteristicas__title">
                            <p>CARACTERÍSTICAS</p>
                        </div>
                        <div className="caracteristicas__suite">
                            <div className="informacion-suites">
                                <p> Precio: Desde $120'000.000</p>
                                <p> Area construida: 25.04m&#178;</p>
                                <p>Apartamentos: 201 y 301</p>
                            </div>
                        </div>
                        <div className="caracteristicas__rightbar" />
                    </div>

                    <div className="info-general">
                        {/* <div className="info-general__modelo-3d">
                            <div className="title-modelo">
                                <p>MODELO 3D</p>
                            </div>
                            {isLoading ? (
                                <div className="loader-container">
                                    <p>Cargando modelo 3D</p>
                                    <Loader />
                                </div>
                            ) : null}
                            <model-viewer
                                id="model-suite"
                                src="../../../Apartamento-301.glb"
                                alt="Ínsula Juananbú"
                                shadow-intensity="1"
                                camera-controls
                                interaction-prompt="auto"
                                ar
                                auto-rotate
                                magic-leap
                                loading="eager"
                                camera-orbit="0deg 0deg 85%"
                            />
                        </div> */}

                        <div className="info-general__plano">
                            <div className="title-plano">
                                <p>PLANO</p>
                            </div>
                            <img className="plano-suite" src={suite301} alt="Plano de la suite"/>
                        </div>
                        {/* <div className="info-general__btn-plano">
                            <p>Ver modelo 3D</p>
                            <img src={arrowIcon} alt="Grupo Ínsula"/>
                        </div> */}
                    </div>
				</div>
			</div>
			
		</Layout>
    );
};

export default Suites;